import React from 'react';
import { navigate } from 'gatsby';

import { Typography, Button, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import { useAuth } from '@context';
import { Protected } from '@components/auth';

import { LoginLayout } from '@components/layout';
import { SimpleButton } from '@components/form';

const UnverifiedEmail = (props) => {
  const auth = useAuth();

  const gotoHome = () => {
    auth.logout();
    navigate('/');
  };

  const sendConfirmation = () => {
    auth.emailVerification(auth.user);

    navigate('/needVerify');
  };

  return (
    <Protected {...props}>
      <LoginLayout>
        <Typography variant="h5" sx={{ mt: '4rem' }}>
          Você precisa confirmar o seu e-mail. Se não recebeu o e-mail de confirmação, clique no botão abaixo para
          reenviar. (verifique na caixa de spam ou lixo eletrônico do seu e-mail)
        </Typography>
        <Box sx={{ width: 1, display: 'flex', mt: '2rem', justifyContent: 'center', gap: '1rem' }}>
          <SimpleButton sx={{ width: 'auto' }} onClick={gotoHome}>
            <HomeIcon sx={{ mr: '1rem' }} /> Voltar ao início
          </SimpleButton>
          <Button variant="contained" color="primary" onClick={sendConfirmation}>
            reenviar
          </Button>
        </Box>
      </LoginLayout>
    </Protected>
  );
};

export default UnverifiedEmail;
